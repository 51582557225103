import {
  adjustDateToUTC,
  calculatePercentage,
  cleanSearchParams,
  convertDateToUTCString,
  emptyCoreUser,
  formatInclusiveEndTime,
  formatWildcardURL,
  systemUser,
} from 'helpers/Utils';
import { compositeData, deleteData, getData, patchData, postData, APIListResponse, putData } from 'APIHandler';
import convertCompositeResponseToObject from 'api/convertCompositeResponseToObject';
import { ItemOrdering } from 'components/Grid/GridTypes';
import { featuredID, carouselID, myTilesID, workplaceID, sharedTilesID, systemUserID, learnContentID, learnFeaturedID } from 'helpers/constants';
import { CompositeAPIRequestType, CompositeAPIReturnType, PaginationQueryParams } from 'types';
import {
  CollectionTileType,
  EditableTileType,
  TileType,
  TileRolesQuery,
  TileRoles,
  TileThumbnail,
  tileTypes,
  TileTypes,
  BoxsetSeasonTileType,
  ThumbnailTileType,
  UploadedVideoTileAttributes,
  TileCookie,
  ArticleTileAttributes,
  BookmarkVideoTileAttributes,
  UploadTileAttributes,
} from 'types/tile';
import { UserCoreType } from 'types/users';
import { LoadDataConfig } from 'hooks/useTable';
import { AudienceCoreType } from 'types/audiences';
import { learnItemTileTypes, learnOnlyTileTypes } from 'helpers/tile';
import { getAudiences } from './AdminAudiencesAPI';

const isURLOrEmptyStr = (val: string) => val.startsWith('http') || val === '';

const readableThumbnailObject = (thumbnail: TileThumbnail) => {
  return {
    ...thumbnail,
    url: isURLOrEmptyStr(thumbnail.url) ? thumbnail.url : formatWildcardURL(thumbnail.url),
    croppedURL: isURLOrEmptyStr(thumbnail.croppedURL) ? thumbnail.croppedURL : formatWildcardURL(thumbnail.croppedURL),
    urlKey: !isURLOrEmptyStr(thumbnail.url) ? thumbnail.url : '',
    croppedURLKey: !isURLOrEmptyStr(thumbnail.croppedURL) ? thumbnail.croppedURL : '',
  };
};

export const formatTileThumbnails = (item: TileType) => {
  const itemObj = JSON.parse(JSON.stringify(item));
  if (item.attributes && 'heroImage' in item.attributes) {
    itemObj.attributes.heroImage = readableThumbnailObject(itemObj.attributes.heroImage);
  }
  if (item.attributes && 'article' in item.attributes) {
    itemObj.attributes.article.thumbnail = readableThumbnailObject(itemObj.attributes.article.thumbnail);
  }
  if (item.attributes && 'thumbnail' in item.attributes) {
    itemObj.attributes.thumbnail = readableThumbnailObject(itemObj.attributes.thumbnail);
  }
  return itemObj;
};

const formatThumbnails = async (items: TileType[]) => {
  return items.map(formatTileThumbnails);
};

export const getAuthorData = async (res: APIListResponse<{ createdBy: string }>) => {
  const authors: string[] = [];
  const compositeRequest: CompositeAPIRequestType[] = [];

  // Only add each author once, we don't want duplicate requests
  res.items.map((item) => {
    if (!authors.includes(item.createdBy as string)) authors.push(item.createdBy as string);
  });

  authors
    .filter((author) => author !== systemUserID)
    .map((author: string) => {
      compositeRequest.push({
        method: 'GET',
        url: `/users/*/user/${author}?detail=core`,
        referenceId: author,
      });
    });

  const authorData = await compositeData('NEW_CompositeUsersAPI', compositeRequest);

  return convertCompositeResponseToObject({
    responses: [
      ...authorData.responses,
      {
        body: systemUser,
        referenceID: systemUserID,
        status: 200,
      },
    ] as CompositeAPIReturnType<UserCoreType>[],
  });
};

// Adds a property containing the first 4 children of each collection to the tile children response
const getCollectionThumbnails = async (res: TileChildrenResponse, userUUID?: string) => {
  const imageTileTypes = ['UPLOAD', 'BOOKMARK', 'ARTICLE', 'VIDEO', 'SCORM', 'UPLOADED_VIDEO', 'BOXSET'];
  const activatedTileTypes = localStorage.getItem('activatedTileTypes') || '';

  const filteredImageTileTypes = imageTileTypes.filter((type) => activatedTileTypes.includes(type));

  const collections = res.items.filter((item) => item.type === 'COLLECTION') as CollectionTileType[];
  if (collections.length === 0) return {};
  const compositeRequest: CompositeAPIRequestType[] = [];

  collections.map((collection: CollectionTileType) => {
    compositeRequest.push({
      method: 'GET',
      url: `/tiles/*/tile/${collection.id}/children?limit=4&offset=0&showRedacted=false&type=${filteredImageTileTypes.join(',')}${
        userUUID ? `&viewAs=${userUUID}` : ''
      }`,
      referenceId: collection.id,
    });
  });

  const collectionChildrenData = await compositeData('CompositeTilesAPI', compositeRequest);

  const formattedCollectionChildrenData = await Promise.all(
    collectionChildrenData.responses.map(async (data: CompositeAPIReturnType) => {
      const body = data.body as TileChildrenResponse;
      return {
        ...data,
        body: {
          ...body,
          items: await formatThumbnails(body.items),
        },
      };
    }),
  );

  return convertCompositeResponseToObject({ responses: formattedCollectionChildrenData });
};

const getTileChildrenCount = async (res: TileChildrenResponse, userUUID?: string, showRedacted = false) => {
  const tileTypesWithChilden = res.items.filter((tile) => tile.type === 'BOXSET' || tile.type === 'SEASON' || tile.type === 'COLLECTION');

  if (tileTypesWithChilden.length === 0) return {};
  const compositeRequest: CompositeAPIRequestType[] = [];

  tileTypesWithChilden.map((tile: TileType) => {
    const params = {
      limit: 0,
      offset: 0,
      type: tile.type !== 'COLLECTION' ? learnItemTileTypes.join(',') : undefined,
      recursive: tile.type !== 'COLLECTION' ? true : undefined,
      viewAs: userUUID,
      showRedacted,
    };
    const queryString = cleanSearchParams(new URLSearchParams(params as unknown as URLSearchParams));

    compositeRequest.push({
      method: 'GET',
      url: `/tiles/*/tile/${tile.id}/children?${decodeURIComponent(queryString.toString())}`,
      referenceId: tile.id,
    });
  });

  const boxsetChildrenData = await compositeData('CompositeTilesAPI', compositeRequest);

  return convertCompositeResponseToObject(boxsetChildrenData);
};

export const setTileCookies = (cookies: TileCookie[], tileType?: TileTypes) => {
  const sameSite = tileType === 'UPLOADED_VIDEO' ? ' SameSite=None; Secure;' : '';
  cookies.map((cookie) => {
    document.cookie = `${cookie.name}=${cookie.value}; expires=${new Date(cookie.expires).toUTCString()}; path=/${
      cookie.path
      // IMPORTANT: BE currently sending us the data subdomain, but we in FE should use the main domain otherwise it won't work
    }; domain=${window.location.hostname};${sameSite}`;
  });

  // Local testing only - Please leave here until learn is in develop
  // cookies.map((cookie) => {
  //   const str = `${cookie.name}=${cookie.value}; expires=${new Date(cookie.expires).toUTCString()}; path=/${
  //     cookie.path
  //     // IMPORTANT: BE currently sending us the data subdomain, but we in FE should use the main domain otherwise it won't work
  //   }; domain=${window.location.hostname};${sameSite}`;
  //   if (sameSite) console.log(str);
  //   document.cookie = str;
  // });
};

export const formatTiles = async (res: TileChildrenResponse, userUUID?: string, showRedacted = false) => {
  const [collectionThumbnails, authors, tileChildrenCount] = await Promise.all([
    getCollectionThumbnails(res, userUUID),
    getAuthorData(res),
    getTileChildrenCount(res, userUUID, showRedacted),
  ]);

  const items = res.items.map((item) => {
    const formattedItem: TileType = {
      ...item,
      createdByDetails: authors ? (authors[item.createdBy as string].body as UserCoreType) : emptyCoreUser,
    };

    if (item.type === 'COLLECTION') {
      (formattedItem as CollectionTileType).attributes.childrenCount = tileChildrenCount
        ? (tileChildrenCount[item.id].body as TileChildrenResponse).itemCount
        : 0;
      (formattedItem as CollectionTileType).attributes.displayThumbnailChildren = collectionThumbnails
        ? (collectionThumbnails[item.id].body as TileChildrenResponse).items
        : [];
    }

    if (formattedItem.attributes && 'article' in formattedItem.attributes) {
      formattedItem.attributes.requiredTimeInSeconds = formattedItem.attributes.article.estimatedReadTime;
      formattedItem.attributes.article.estimatedReadTime = formattedItem.attributes.article.estimatedReadTime / 60;
      formattedItem.attributes.heroImage = formattedItem.attributes.article.thumbnail;
      formattedItem.attributes.heroTextOptions = formattedItem.attributes.article.textOptions;
    }

    if (item.type === 'BOXSET' || item.type === 'SEASON') {
      (formattedItem as BoxsetSeasonTileType).attributes.itemCount = tileChildrenCount
        ? (tileChildrenCount[item.id].body as TileChildrenResponse).itemCount
        : 0;
    }

    if (item.type === 'UPLOADED_VIDEO') {
      (formattedItem.attributes as UploadedVideoTileAttributes).upload = {
        fileName: (item.attributes as UploadedVideoTileAttributes).fileName,
        scanID: '',
        fileSizeInBytes: (item.attributes as UploadedVideoTileAttributes).totalFileSizeInBytes,
        fileType: '',
        scannedAt: (item.attributes as UploadedVideoTileAttributes).transcodedAt,
        downloadURL: '',
      };
    }

    // Set Cookies
    if (item.cookies && (item.type === 'UPLOADED_VIDEO' || item.type === 'SCORM')) {
      setTileCookies(item.cookies, item.type);
    }

    return formattedItem;
  });

  return { ...res, items: await formatThumbnails(items as TileType[]) };
};

export const getTile = (tileUUID: string, viewAs?: string) => {
  return getData('TilesAPI', `${tileUUID}${viewAs ? `?viewAs=${viewAs}` : ''}`);
};

// Get a tiles audiences, admin only
export const getTileAudiences = (tileUUID: string) => {
  // If new tile return empty request
  if (tileUUID === '') return Promise.resolve({ items: [], itemCount: 0 });
  return getData('AudiencesAPI', `tile/${tileUUID}/audiences`);
};

export const updateTile = (tileUUID: string, updatedTileProperties: Partial<EditableTileType>) => {
  return patchData('TilesAPI', `${tileUUID}`, updatedTileProperties);
};

export const deleteTile = (tileUUID: string) => {
  return deleteData('TilesAPI', `${tileUUID}`);
};

export const deleteTiles = (tileUUIDs: string[]) => {
  return compositeData(
    'CompositeTilesAPI',
    tileUUIDs.map((tileUUID: string) => {
      return {
        method: 'DELETE',
        url: `/tiles/*/tile/${tileUUID}`,
        referenceId: tileUUID,
      };
    }),
  );
};

export const createTileChild = (parentUUID: string, tile: TileType) => {
  return postData('TilesAPI', `${parentUUID}/children`, tile);
};

export const getDeactivatedTileTypes = (activatedTileTypes: TileTypes[]) => {
  return tileTypes.filter((type) => !activatedTileTypes.includes(type));
};

interface TilesIdAndType {
  id: string;
  type: TileTypes;
}

export const sortDeactivatedToEnd = (data: TilesIdAndType[], activatedTileTypes: TileTypes[]) => {
  const deactivatedTypes = getDeactivatedTileTypes(activatedTileTypes);
  const newArray = [...data];

  // Sends all deactivated tile types to the end of the array
  const sortedArray = newArray.sort((a, b) => {
    if (deactivatedTypes.includes(a.type) && !deactivatedTypes.includes(b.type)) {
      return 1;
    } else if (!deactivatedTypes.includes(a.type) && deactivatedTypes.includes(b.type)) {
      return -1;
    } else {
      return 0;
    }
  });

  return sortedArray.map((item) => item.id);
};

export const formatCollectionOrder = (newOrder: ItemOrdering, fullOrder?: string[]) => {
  // fullOrder should be passed if the length of the reordering is less than the full array
  // E.g if only displaying 30 tiles out of a possible 120, fullOrder will be all 120 in the original order
  const entries = Object.entries(newOrder);

  const arrayOrder = entries
    .sort((a, b) => {
      if (a[1] > b[1]) return 1;
      return -1;
    })
    .map((val) => val[0])
    .filter((val) => val !== 'CREATETILE');

  if (fullOrder) {
    const restOfOrder = [...fullOrder];
    restOfOrder.splice(0, arrayOrder.length);
    arrayOrder.push(...restOfOrder);
  }

  return arrayOrder;
};

export const updateCollectionOrder = async (parentUUID: string, newOrder: string[]) => {
  return patchData('TilesAPI', `${parentUUID}/children`, {
    newOrder,
  }).catch((err) => Promise.reject(err));
};

export const addExistingTileToCollection = (parentTileUUID: string, tileUUID: string) => {
  return getData('TilesAPI', `${parentTileUUID}/children/${tileUUID}`);
};

export const cloneTileToCollection = (parentTileUUID: string, tileUUID: string) => {
  return postData('TilesAPI', `${parentTileUUID}/children/${tileUUID}`);
};

export const removeTileFromCollection = (parentTileUUID: string, tileUUID: string) => {
  return deleteData('TilesAPI', `${parentTileUUID}/children/${tileUUID}`);
};

export const removeTilesFromCollection = (parentTileUUID: string, tileUUIDs: string[]) => {
  return compositeData(
    'CompositeTilesAPI',
    tileUUIDs.map((tileUUID: string) => {
      return {
        method: 'DELETE',
        url: `/tiles/*/tile/${parentTileUUID}/children/${tileUUID}`,
        referenceId: tileUUID,
      };
    }),
  );
};

// Possible replacement by backend for single endpoint accepting arr of ID's
export const updateTileAudiences = (tile: TileType) => {
  return putData('AudiencesAPI', `tile/${tile.id}/audiences`, {
    audiences: tile.audiences?.map((audience) => {
      return audience.id;
    }),
  });
};

const updateFeaturedCarousel = (tile: TileType, featuredCollectionID = featuredID, carouselCollectionID = carouselID) => {
  const requests = [];

  if (featuredCollectionID && tile.isFeatured !== undefined)
    requests.push({
      method: tile.isFeatured ? 'PATCH' : 'DELETE',
      url: `/tiles/*/tile/${featuredCollectionID}/children/${tile.id}`,
      referenceId: 'featured',
    });

  if (carouselCollectionID && tile.isCarousel !== undefined)
    requests.push({
      method: tile.isCarousel ? 'PATCH' : 'DELETE',
      url: `/tiles/*/tile/${carouselCollectionID}/children/${tile.id}`,
      referenceId: 'carousel',
    });

  if (requests.length === 0) return Promise.resolve();

  return compositeData(
    'CompositeTilesAPI',
    requests,
    // TODO - reintroduce this error message once spoken to BE about approach
    // true,
  );
};

export const replaceTileChildren = (tileUUID: string, childrenUUIDs: string[]) => {
  return putData('TilesAPI', `${tileUUID}/children`, { children: childrenUUIDs });
};

export const addOrEditTile = async (tile: TileType, collectionID: string, featuredCollectionID = featuredID, carouselCollectionID = carouselID) => {
  const updatedTile = JSON.parse(JSON.stringify(tile));
  if (tile.id) await updateTile(tile.id, tile);
  if (!tile.id) {
    const t = await createTileChild(collectionID, tile);
    updatedTile.id = t.Tile.id;
    updatedTile.createdBy = t.Tile.createdBy;
  }
  if (tile.isManagedByOrganisation) {
    await updateTileAudiences(updatedTile);
    await updateFeaturedCarousel(updatedTile, featuredCollectionID, carouselCollectionID);
  }

  return updatedTile;
};

export interface ChildOrgLearnItemProps {
  tileUpdates: Partial<EditableTileType>;
  shouldUpdateAudiences?: boolean;
  shouldUpdateFeatured?: boolean;
}

export const updateChildOrgLearnItem = async (tile: ThumbnailTileType, propertiesToUpdate: ChildOrgLearnItemProps) => {
  if (Object.keys(propertiesToUpdate.tileUpdates).length > 0) await updateTile(tile.id, propertiesToUpdate.tileUpdates);

  if (propertiesToUpdate.shouldUpdateAudiences) await updateTileAudiences(tile);
  if (propertiesToUpdate.shouldUpdateFeatured) await updateFeaturedCarousel(tile, learnFeaturedID);

  return tile;
};

export const formatTile = async (tile: TileType, viewAs?: string, showRedacted?: boolean) => {
  const data = await formatTiles({ items: [tile], itemCount: 1 }, viewAs, showRedacted);
  return data.items[0];
};

export type TileChildrenResponse = APIListResponse<TileType>;

export interface GetTileChildrenQueryParams extends PaginationQueryParams {
  sortBy?: string;
  // CSV of tile types - 'BOOKMARK,VIDEO,ARTICLE'
  type?: string;
  search?: string;
  viewAs?: string;
  recursive?: boolean;
  tileID?: string;
  audiences?: string;
  isMandatory?: boolean;
  isCompleted?: boolean;
  'dueAt.start'?: string;
  'dueAt.end'?: string;
  'endAt.start'?: string;
  'endAt.end'?: string;
  'createdAt.start'?: string;
  'createdAt.end'?: string;
  'updatedAt.start'?: string;
  'updatedAt.end'?: string;
  'reaction.type'?: string;
  showRedacted?: boolean;
  typesRequiringVisibleDescendants?: string;
  acceptedDescendantTypes?: string;
}

// If you use this function directly on its own uploaded images will not work
export const getTileChildrenByID = (tileUUID: string, params?: GetTileChildrenQueryParams) => {
  const queryObject = { limit: 999, offset: 0, recursive: false, ...params };
  const searchObject = {
    ...queryObject,
    limit: queryObject.limit.toString(),
    offset: queryObject.offset.toString(),
    recursive: queryObject.recursive?.toString() || 'false',
    isCompleted: queryObject.isCompleted?.toString() || '',
    isMandatory: queryObject.isMandatory?.toString() || '',
    showRedacted: queryObject.showRedacted?.toString() || '',
  };
  const queryString = cleanSearchParams(new URLSearchParams(searchObject));

  return getData('TilesAPI', `${tileUUID}/children?${decodeURIComponent(queryString.toString())}`);
};

export const getFormattedTileChildrenByID = async (tileUUID: string, params?: GetTileChildrenQueryParams) => {
  const data = await getTileChildrenByID(tileUUID, params);

  return formatTiles(data, params?.viewAs, params?.showRedacted);
};

interface GetTileRolesQueryParams extends PaginationQueryParams {
  location?: string; // UUID, comma separated if multiple (no spaces)
  department?: string; // UUID, comma separated if multiple (no spaces)
  role?: TileRolesQuery;
  search?: string;
}

export const getTileRoles = (tileUUID: string, params?: GetTileRolesQueryParams) => {
  const queryObject = { limit: 999, offset: 0, ...params };
  const queryString = cleanSearchParams(
    new URLSearchParams({ ...queryObject, limit: queryObject.limit.toString(), offset: queryObject.offset.toString() }),
  );

  return getData('TilesAPI', `${tileUUID}/roles?${queryString}`);
};

export const getTileRoleForUser = (tileUUID: string, userUUID: string) => {
  return getData('TilesAPI', `${tileUUID}/roles/${userUUID}?limit=3&offset=0`);
};

export const updateTileRoleForUsers = (tileUUID: string, userUUIDs: string[], role?: TileRoles) => {
  // If no 'role' is specified, this will remove the user from the collection instead so we send an empty array;
  const roleData = role ? [role] : [];

  // Bulk update with composite
  if (userUUIDs.length > 1) {
    return compositeData(
      'CompositeTilesAPI',
      userUUIDs.map((userUUID) => {
        return {
          method: 'PATCH',
          url: `/tiles/*/tile/${tileUUID}/roles/${userUUID}`,
          referenceId: `${userUUID}`,
          body: { role: roleData },
        };
      }),
    );
  }

  // update single user
  return patchData('TilesAPI', `${tileUUID}/roles/${userUUIDs[0]}`, { role: roleData });
};

export const getTileAndChildren = async (tileUUID: string, params?: GetTileChildrenQueryParams) => {
  const data = await Promise.all([getTile(tileUUID, params?.viewAs), getTileChildrenByID(tileUUID, params)]);

  return {
    tile: await formatTile(data[0].Tile, params?.viewAs),
    children: await formatTiles(data[1], params?.viewAs),
  };
};

export const getCollectionIds = async (collectionUUID: string, userUUID?: string) => {
  const viewAs = userUUID ? `&viewAs=${userUUID}` : '';
  const children: TileChildrenResponse = await getData('TilesAPI', `${collectionUUID}/children?limit=999&offset=0${viewAs}`);

  return (
    children?.items?.map((item) => {
      return { id: item.id, type: item.type };
    }) || []
  );
};

export const getMyHubTiles = async (userUUID: string, params?: PaginationQueryParams & { type?: string }) => {
  const limit = params?.limit || 999;
  const offset = params?.offset || 0;

  const data = await compositeData('CompositeTilesAPI', [
    {
      method: 'GET',
      url: `/tiles/*/tile/${featuredID}/children?limit=100&offset=0&viewAs=${userUUID}&type=${params?.type || ''}`,
      referenceId: 'featured',
    },
    {
      method: 'GET',
      url: `/tiles/*/tile/${carouselID}/children?limit=100&offset=0&viewAs=${userUUID}&type=${params?.type || ''}`,
      referenceId: 'carousel',
    },
    {
      method: 'GET',
      url: `/tiles/*/tile/${myTilesID}/children?limit=${limit}&offset=${offset}&viewAs=${userUUID}&type=${params?.type || ''}`,
      referenceId: 'mytiles',
    },
  ]);

  // Delete any redacted tiles from mytiles (TEMPORARY short term fix until BE remove these automatically with audiences)
  const allUserTiles = data.responses[2].body.items;
  if (allUserTiles.length > 0) {
    const redactedItemsToDelete: string[] = [];
    allUserTiles.forEach((tile: TileType) => {
      if (tile.type === '') {
        redactedItemsToDelete.push(tile.id);
      }
    });
    if (redactedItemsToDelete.length > 0) {
      removeTilesFromCollection(myTilesID, redactedItemsToDelete);
    }
  }

  return {
    featuredTiles: await formatTiles(data.responses[0].body, userUUID),
    carouselTiles: await formatTiles(data.responses[1].body, userUUID),
    userTiles: await formatTiles(data.responses[2].body, userUUID),
  };
};

export const getTilesBySearchTerm = async (searchTerm: string, allowedTileTypes = tileTypes, userUUID?: string) => {
  const type = allowedTileTypes.join(',');
  const data = await compositeData('CompositeTilesAPI', [
    {
      method: 'GET',
      url: `/tiles/*/tile/${workplaceID}/children?limit=999&offset=0&search=${searchTerm}&type=${type}&recursive=true&showRedacted=false${
        userUUID ? `&viewAs=${userUUID}` : ''
      }`,
      referenceId: 'workplace',
    },
    {
      method: 'GET',
      url: `/tiles/*/tile/${myTilesID}/children?limit=999&offset=0&search=${searchTerm}&type=${type}&recursive=true&showRedacted=false${
        userUUID ? `&viewAs=${userUUID}` : ''
      }`,
      referenceId: 'mytiles',
    },
    {
      method: 'GET',
      url: `/tiles/*/tile/${sharedTilesID}/children?limit=999&offset=0&search=${searchTerm}&type=${type}&recursive=true&showRedacted=false${
        userUUID ? `&viewAs=${userUUID}` : ''
      }`,
      referenceId: 'sharedtiles',
    },
    {
      method: 'GET',
      url: `/tiles/*/tile/${learnContentID}/children?limit=999&offset=0&search=${searchTerm}&type=${type}&recursive=true&showRedacted=false${
        userUUID
          ? `&viewAs=${userUUID}&typesRequiringVisibleDescendants=BOXSET&acceptedDescendantTypes=${[...learnItemTileTypes, 'SEASON'].join(',')},`
          : ''
      }`,
      referenceId: 'learnContent',
    },
  ]);
  return {
    workplaceTiles: await formatTiles(data.responses[0].body, userUUID),
    userTiles: await formatTiles(data.responses[1].body, userUUID),
    sharedTiles: await formatTiles(data.responses[2].body, userUUID),
    learnContent: await formatTiles(data.responses[3].body, userUUID),
  };
};

export const getMultipleCollectionsUserRole = async (userUUID: string, items: TileType[]) => {
  const roles = await compositeData(
    'CompositeTilesAPI',
    items.map((item) => {
      return {
        method: 'GET',
        url: `/tiles/*/tile/${item.id}/roles/${userUUID}?limit=3&offset=0`,
        referenceId: item.id,
      };
    }),
  );

  return convertCompositeResponseToObject(roles);
};

export const getAdminCollections = async (searchTerm = '') => {
  const data = await getFormattedTileChildrenByID(workplaceID, { search: searchTerm, type: 'COLLECTION' });

  return data.items;
};

export const getUserCollections = async (searchTerm = '', userUUID: string) => {
  const data = await compositeData('CompositeTilesAPI', [
    {
      method: 'GET',
      url: `/tiles/*/tile/${sharedTilesID}/children?limit=999&offset=0&type=COLLECTION&search=${searchTerm}&viewAs=${userUUID}`,
      referenceId: 'carousel',
    },
    {
      method: 'GET',
      url: `/tiles/*/tile/${myTilesID}/children?limit=999&offset=0&type=COLLECTION&search=${searchTerm}&viewAs=${userUUID}`,
      referenceId: 'mytiles',
    },
  ]);

  const sharedCollectionRoles = await getMultipleCollectionsUserRole(userUUID, data.responses[0].body.items);

  const sharedTilesICanEdit = data.responses[0].body.items.filter((item: TileType) => {
    if (!sharedCollectionRoles) return false;
    const roles = (sharedCollectionRoles[item.id].body as { items: { role: TileRoles }[] }).items;
    if (roles.length < 1) return false;
    return roles[0].role !== 'TILE_VIEWER';
  });

  const formattedTiles = {
    shared: await formatTiles({ ...data.responses[0].body, items: sharedTilesICanEdit }, userUUID, true),
    standard: await formatTiles(
      { ...data.responses[1].body, items: data.responses[1].body.items.filter((item: TileType) => !item.isManagedByOrganisation) },
      userUUID,
    ),
  };

  return [...formattedTiles.shared.items, ...formattedTiles.standard.items];
};

export const addTileToCollections = (tile: TileType, collections: string[]) => {
  return compositeData(
    'CompositeTilesAPI',
    collections.map((collectionID) => {
      return {
        method: tile.isManagedByOrganisation ? 'PATCH' : 'POST',
        url: `/tiles/*/tile/${collectionID}/children/${tile.id}`,
        referenceId: collectionID,
      };
    }),
    true,
  );
};

export const getCollections = async (searchTerm = '', userUUID: string, isAdmin: boolean) => {
  return isAdmin ? getAdminCollections(searchTerm) : getUserCollections(searchTerm, userUUID);
};

export const addTilesToCollection = (collectionUIID: string, tiles: string[]) => {
  return compositeData(
    'CompositeTilesAPI',
    tiles.map((tileID) => {
      return {
        method: 'PATCH',
        url: `/tiles/*/tile/${collectionUIID}/children/${tileID}`,
        referenceId: tileID,
      };
    }),
    true,
  );
};

export const getFormattedTile: (tileUUID: string, viewAs?: string, showRedacted?: boolean) => Promise<TileType> = async (
  tileUUID: string,
  viewAs?: string,
  showRedacted?: boolean,
) => {
  const tile = await getTile(tileUUID, viewAs);

  return formatTile(tile.Tile, viewAs, showRedacted);
};

export const isTileInCollection = async (tileUUID: string, collectionUUID: string) => {
  if (tileUUID === '') return false;
  const data = await getData('TilesAPI', `${collectionUUID}/children?tileID=${tileUUID}&limit=999&offset=0`);
  return data.items.length !== 0;
};

export const getMultipleTilesAudiences = async (tileUUIDs: string[], params?: PaginationQueryParams) => {
  const queryObject = { limit: 999, offset: 0, ...params };
  const queryString = cleanSearchParams(
    new URLSearchParams({ ...queryObject, limit: queryObject.limit.toString(), offset: queryObject.offset.toString() }),
  );

  const data = await compositeData(
    'CompositeAudiencesAPI',
    tileUUIDs.map((tileUUID) => {
      return {
        method: 'GET',
        url: `/audiences/*/tile/${tileUUID}/audiences?${queryString.toString()}`,
        referenceId: tileUUID,
      };
    }),
  );

  return convertCompositeResponseToObject(data);
};

export const getTileAndOrgAudiences = async (id: string, featuredCollectionID = featuredID, carouselCollectionID = carouselID) => {
  const data = await Promise.all([
    getTileAudiences(id),
    getAudiences(),
    // Can pass in custom ID to base isFeatured and isCarousel property from, needed for learn
    isTileInCollection(id, featuredCollectionID),
    carouselCollectionID && isTileInCollection(id, carouselCollectionID),
  ]);

  return {
    tileAudiences: data[0].items,
    organisationAudiences: data[1],
    isFeatured: data[2],
    isCarousel: data[3],
  };
};

/* 
Fetch the Tiles of Type COLLECTION that have been shared with the user
*/
export const getSharedCollections = async (userUUID: string) => {
  const data = await getFormattedTileChildrenByID(sharedTilesID, {
    limit: 999,
    offset: 0,
    type: 'COLLECTION',
    viewAs: userUUID,
    showRedacted: false,
  });

  const roles = await getMultipleCollectionsUserRole(userUUID, data.items);
  return {
    data,
    roles,
  };
};

export const cloneTileToOrganisations = async (tileUUID: string, organisationUUIDs: string[], containerID?: string) => {
  return putData(
    'TilesAPI',
    `${tileUUID}/clones`,
    organisationUUIDs.map((orgUUID) => {
      return { organisationID: orgUUID, ...(containerID ? { containerID } : {}) };
    }),
  );
};

export const getTileClones = (tileUUID: string) => {
  return getData('TilesAPI', `${tileUUID}/clones?limit=999`);
};

export const getSeasonsAndItems = async (tileUUID: string, viewAs?: string) => {
  const seasons = await getFormattedTileChildrenByID(
    tileUUID,
    // If viewAs provided only send seasons with active children
    viewAs ? { viewAs, typesRequiringVisibleDescendants: 'SEASON', acceptedDescendantTypes: learnItemTileTypes.join(','), showRedacted: false } : {},
  );

  if (seasons.itemCount === 0) return [];

  const items = await Promise.all(seasons.items.map((season) => getFormattedTileChildrenByID(season.id, { viewAs, showRedacted: !viewAs })));

  return seasons.items.map((season, i) => ({ season, items: items[i].items }));
};

export interface BoxsetSeasonData {
  season: BoxsetSeasonTileType;
  items: TileType[];
}
export interface BoxsetResponseType {
  boxset: BoxsetSeasonTileType;
  seasons: BoxsetSeasonData[];
}

export const getBoxset = async (tileUUID: string, viewAs?: string): Promise<BoxsetResponseType> => {
  const data = await Promise.all([getFormattedTile(tileUUID, viewAs, !viewAs), getSeasonsAndItems(tileUUID, viewAs)]);

  return { boxset: data[0] as BoxsetSeasonTileType, seasons: data[1] };
};

export const getSeasonTotalDuration = (season: BoxsetSeasonData) => {
  return season.items.reduce(
    (total, item) => total + (item.attributes as BookmarkVideoTileAttributes | ArticleTileAttributes | UploadTileAttributes).requiredTimeInSeconds,
    0,
  );
};

export const getBoxsetTotalDuration = (seasons: BoxsetSeasonData[]) => {
  // Will return time in seconds as we are using the required time
  return seasons.map((seasonObj) => getSeasonTotalDuration(seasonObj)).reduce((boxsetTotal, seasonTotal) => boxsetTotal + seasonTotal, 0);
};

export const getSeasonProgress = (season: BoxsetSeasonData) => {
  if (season.items.length === 0) return 0;

  const mandatoryItems = season.items.filter((item) => item.isMandatory);
  const seasonItemsCompleted = mandatoryItems.reduce((total, item) => total + (item.userData?.isCompleted && item.userData?.isPassed ? 1 : 0), 0);

  return Math.round(calculatePercentage(seasonItemsCompleted, mandatoryItems.length));
};

interface SeasonItemsCompleted {
  seasonItemsCompleted: number;
  itemCount: number;
}

export const getBoxsetProgress = (seasons: BoxsetSeasonData[]) => {
  const seasonsProgress: SeasonItemsCompleted[] = seasons.map((seasonObj) => {
    const mandatoryItems = seasonObj.items.filter((item) => item.isMandatory);
    return {
      seasonItemsCompleted: mandatoryItems.reduce((total, item) => total + (item.userData?.isCompleted && item.userData?.isPassed ? 1 : 0), 0),
      itemCount: mandatoryItems.length,
    };
  });

  const calculatedObject = seasonsProgress.reduce(
    (acc, el) => {
      acc.seasonItemsCompleted += el.seasonItemsCompleted;
      acc.itemCount += el.itemCount;
      return acc;
    },
    {
      seasonItemsCompleted: 0,
      itemCount: 0,
    },
  );

  return Math.round(calculatePercentage(calculatedObject.seasonItemsCompleted, calculatedObject.itemCount));
};

export const formatTileForPost = (tile: ThumbnailTileType) => {
  const unModifiedTile = JSON.parse(JSON.stringify(tile));
  const formattedTile = JSON.parse(JSON.stringify(tile));

  if (!('heroImage' in formattedTile.attributes)) {
    const emptyThumbnail = {
      colour: '',
      url: '',
      urlKey: '',
      croppedURL: '',
      croppedURLKey: '',
      filter: 'none',
      transforms: {
        rotation: 0,
        isFlippedVertical: false,
        isFlippedHorizontal: false,
        zoom: 1,
      },
    };
    // Adds in a heroImage to ensure it has same obj properties as other tile types - this will be returned in future and can be removed
    unModifiedTile.attributes.heroImage = emptyThumbnail;
    formattedTile.attributes.heroImage = emptyThumbnail;
  }

  if (formattedTile.attributes.thumbnail.url) {
    formattedTile.attributes.thumbnail.url = unModifiedTile.attributes.thumbnail.urlKey || unModifiedTile.attributes.thumbnail.url;
    formattedTile.attributes.thumbnail.croppedURL =
      unModifiedTile.attributes.thumbnail.croppedURLKey || unModifiedTile.attributes.thumbnail.croppedURL;
  } else {
    // If no existing thumbnail set it to the heroImage
    formattedTile.attributes.thumbnail.url = unModifiedTile.attributes.heroImage.urlKey || unModifiedTile.attributes.heroImage.url;
    formattedTile.attributes.thumbnail.croppedURL =
      unModifiedTile.attributes.heroImage.croppedURLKey || unModifiedTile.attributes.heroImage.croppedURL;
  }
  formattedTile.attributes.heroImage.url = unModifiedTile.attributes.heroImage.urlKey || unModifiedTile.attributes.heroImage.url;
  formattedTile.attributes.heroImage.croppedURL = unModifiedTile.attributes.heroImage.croppedURLKey || unModifiedTile.attributes.thumbnail.croppedURL;

  if (formattedTile.type === 'ARTICLE') {
    formattedTile.attributes.article.title = formattedTile.attributes.article.title || unModifiedTile.name;
    formattedTile.attributes.article.estimatedReadTime = parseInt(formattedTile.attributes.estimatedReadTime) * 60;
    if (formattedTile.attributes.requiredTimeInSeconds)
      formattedTile.attributes.article.estimatedReadTime = formattedTile.attributes.requiredTimeInSeconds;
    // If we are setting learn hero image use that
    if (formattedTile.attributes.heroImage) {
      formattedTile.attributes.article.thumbnail.url = formattedTile.attributes.heroImage.url;
    }
  }

  if ((formattedTile.type === 'UPLOAD' || formattedTile.type === 'SCORM') && !formattedTile.attributes.upload.scanID) {
    delete formattedTile.attributes.upload;
  }

  if (formattedTile.type === 'UPLOADED_VIDEO') {
    if (formattedTile.attributes.upload.scanID) {
      formattedTile.attributes.transcode = { jobID: formattedTile.attributes.upload.scanID };
      formattedTile.attributes.fileName = formattedTile.attributes.upload.fileName;
    }
    delete formattedTile.attributes.upload;
  }

  if (formattedTile.id.includes('PLACEHOLDER_')) {
    delete formattedTile.id;
  }

  if (formattedTile.dueAt) formattedTile.dueAt = formatInclusiveEndTime(adjustDateToUTC(formattedTile.dueAt));
  if (formattedTile.startAt) formattedTile.startAt = convertDateToUTCString(adjustDateToUTC(formattedTile.startAt));
  if (formattedTile.endAt) formattedTile.endAt = formatInclusiveEndTime(adjustDateToUTC(formattedTile.endAt));

  return formattedTile;
};

export const createTileReaction = (tileUUID: string, userUUID: string, reaction: 'LIKE') => {
  return putData('TilesAPI', `${tileUUID}/user/${userUUID}/reaction`, { reaction });
};

export const deleteTileReaction = (tileUUID: string, userUUID: string) => {
  return deleteData('TilesAPI', `${tileUUID}/user/${userUUID}/reaction`);
};

interface TileProgress {
  progress: number; // From 0-1 e.g 0, 0.5, 1
  isCompleted: boolean;
  isPassed: boolean;
}

export const interactedProgress = 0.001; // This value is set to indicate that a user has 'seen' an item or interacted with it for the first time

export const tileCompleted: TileProgress = { progress: 1, isCompleted: true, isPassed: true };

// This may be more useful for reporting etc because the user's tile progress is being included in the main tile object
export const getTileProgress = (tileUUID: string, userUUID: string) => {
  return getData('TilesAPI', `${tileUUID}/user/${userUUID}/progress`);
};

export const setTileProgress = (progressSettings: Partial<TileProgress>, tileUUID: string, userUUID: string) => {
  return patchData('TilesAPI', `${tileUUID}/user/${userUUID}/progress`, progressSettings);
};

export const setTileProgressComplete = (orgPath: string, tileUUID: string, userUUID: string) => {
  return patchData('AppAPI', `tiles/${orgPath}/tile/${tileUUID}/user/${userUUID}/progress`, tileCompleted);
};

export const getItemBoxsets = async (tileUUID: string, userUUID?: string) => {
  const data = await getData('TilesAPI', `${tileUUID}/parents?recursive=true&type=BOXSET${userUUID ? `&viewAs=${userUUID}` : ''}`);

  if (!data.items) return data;

  const formattedItems = await formatThumbnails(data.items);

  return {
    ...data,
    items: formattedItems,
  };
};

export const onlyShowActiveBoxsetsFilters = {
  typesRequiringVisibleDescendants: 'BOXSET',
  acceptedDescendantTypes: [...learnItemTileTypes, 'SEASON'].join(','),
};

export const getTilesWithAudiences: (config: LoadDataConfig, activatedTileTypes: string[]) => Promise<APIListResponse<TileType>> = async (
  config,
  activatedTileTypes,
) => {
  const { pagination, filters, searchTerm } = config;
  const tiles = await getFormattedTileChildrenByID(workplaceID, {
    ...pagination,
    ...filters,
    type: filters?.type || activatedTileTypes.filter((type) => ![...learnOnlyTileTypes, '', 'IMAGE_BANK', 'XERO'].includes(type)).join(','),
    search: searchTerm,
  });
  const tileAudiences = await getMultipleTilesAudiences(
    tiles.items.map((tile) => tile.id),
    { limit: 3, offset: 0 },
  );

  return {
    items: tiles.items.map((tile: TileType) => {
      return {
        ...tile,
        audiences: tileAudiences && tileAudiences[tile.id] ? (tileAudiences[tile.id].body as APIListResponse<AudienceCoreType>).items : [],
      };
    }),
    itemCount: tiles.itemCount,
  };
};
